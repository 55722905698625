var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"gateways-info-tab",attrs:{"element-loading-background":"white"}},[(!_vm.loading)?_c('el-row',[_c('layout-toolbar',{staticClass:"post-header-right",attrs:{"type":"flex","justify":"end"}},[_c('el-col',{attrs:{"span":4.5}},[_c('er-input',{attrs:{"inputType":"search","size":"mini","suffix-icon":"el-icon-search","placeholder":_vm.$t('Comn_search_by_field', { field: _vm.$t('Comn_gateway') })},on:{"change":_vm.handelSearchInput},model:{value:(_vm.searchGateway),callback:function ($$v) {_vm.searchGateway=$$v},expression:"searchGateway"}})],1),_vm._v(" "),_c('er-button',{attrs:{"btnType":"refresh","showIcon":true,"type":"text"},on:{"click":_vm.handleRefresh}})],1),_vm._v(" "),_c('el-row',{staticClass:"table-container"},[_c('er-data-tables',{key:_vm.$i18n.locale,ref:"gatewayInfoTable",attrs:{"table-data":_vm.tableDataArr,"el-table-props":_vm.tableProps,"action-column":false,"filters":_vm.tableFilters,"uniqueId":"gateway-info-table","type":"white-header-table","columns":_vm.filteredColumns,"searchOnTableData":_vm.searchGateway},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":_vm.upm__getPrefDeviceIdentityKey,"withoutHighlightStrings":_vm.upm__getValue(row.data)}})]}},{key:"devices_count",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(row.data.devices_count))+"\n        ")]}},{key:"ip_address",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"ip_address","withoutHighlightStrings":row.data.ip_address}})]}},{key:"mac_address",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"mac_address","withoutHighlightStrings":row.data.mac_address}})]}},{key:"wifi_connection",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"wifi_connection","withoutHighlightStrings":_vm.ftm__formatEmpty(row.data.wifi_connection)}})]}},{key:"signal",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.ftm__formatEmpty(row.data.signal, undefined, undefined, true))+"\n        ")]}},{key:"lora_channel",fn:function(ref){
var row = ref.row;
return [_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"lora_channel","withoutHighlightStrings":_vm.ftm__digitPrecision(row.data.lora_channel / 1000000, 2)}})]}},{key:"last_communication",fn:function(ref){
var row = ref.row;
return [_c('i',{staticClass:"material-icons-round communication-status-icon",style:(("color:" + (_vm.getStatusTitleAndColor(row.data).color))),attrs:{"title":_vm.getStatusTitleAndColor(row.data).title}},[_vm._v("signal_cellular_alt")])]}},{key:"time",fn:function(ref){
var row = ref.row;
return [_vm._v("\n          "+_vm._s(_vm.lastCommunicationDate(row.data))+"\n        ")]}},{key:"location",fn:function(ref){
var row = ref.row;
return [(row.data.location._id !== 'NO_LOCATIONS')?_c('el-tag',{attrs:{"effect":"dark","size":"mini"}},[_c('er-highlight-search-component',{attrs:{"highlightStrings":row.data.highlightStrings,"individualRowData":row.data,"keyName":"location.name","withoutHighlightStrings":row.data.location.name}})],1):_c('span',{key:row.data.location._id},[_vm._v(_vm._s(_vm.ftm__formatEmpty(row.data.location.name)))])]}}],null,false,4063541359)})],1),_vm._v(" "),_c('el-row',[_c('ul',{staticClass:"legend"},[_c('li',{staticClass:"legend-item"},[_c('i',{staticClass:"material-icons-round communication-status-icon",style:("color:#33A02C")},[_vm._v("signal_cellular_alt")]),_vm._v(" "),_c('p',{staticClass:"legend-title"},[_vm._v(_vm._s(_vm.$t("online")))])]),_vm._v(" "),_c('li',{staticClass:"legend-item"},[_c('i',{staticClass:"material-icons-round communication-status-icon",style:("color:#F08029")},[_vm._v("signal_cellular_alt")]),_vm._v(" "),_c('p',{staticClass:"legend-title"},[_vm._v("\n            "+_vm._s(_vm.$t("offline_since_time", {
                time: "20",
                units: _vm.$tc("minutes", 3),
              }))+"\n          ")])]),_vm._v(" "),_c('li',{staticClass:"legend-item"},[_c('i',{staticClass:"material-icons-round communication-status-icon",style:("color:#E31A1C")},[_vm._v("signal_cellular_alt")]),_vm._v(" "),_c('p',{staticClass:"legend-title"},[_vm._v("\n            "+_vm._s(_vm.$t("offline_since_time", {
                time: "30",
                units: _vm.$tc("minutes", 3),
              }))+"\n          ")])])])])],1):(_vm.loading)?_c('div',{staticClass:"loading_class"}):_c('er-no-ponds')],1)}
var staticRenderFns = []

export { render, staticRenderFns }